export const transition = [
    {
        name: "无",
        value: "",
        img: require("@/assets/images/fission/gif/wu.png"),
        select: true,
    },
    {
        name: "对角切换",
        value: "directional",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/directional.webp',
        select: false,
    },
    {
        name: "漩涡",
        value: "displacement",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/displacement.webp',
        select: false,
    },
    {
        name: "栅格",
        value: "windowslice",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/windowslice.webp',
        select: false,
    },
    {
        name: "垂直领结",
        value: "bowTieVertical",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/bowTieVertical.webp',
        select: false,
    },
    {
        name: "水平领结",
        value: "bowTieHorizontal",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/bowTieHorizontal.webp',
        select: false,
    },
    {
        name: "放大消失",
        value: "simplezoom",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/simplezoom.webp',
        select: false,
    },
    {
        name: "线性模糊",
        value: "linearblur",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/linearblur.webp',
        select: false,
    },
    {
        name: "水滴",
        value: "waterdrop",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/waterdrop.webp',
        select: false,
    },
    {
        name: "故障",
        value: "glitchmemories",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/glitchmemories.webp',
        select: false,
    },
    {
        name: "波点",
        value: "polka",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/polka.webp',
        select: false,
    },
    // 
    {
        name: "蔓延",
        value: "perlin",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/perlin.webp',
        select: false,
    },
    {
        name: "扭曲旋转",
        value: "directionalwarp",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/directionalwarp.webp',
        select: false,
    },
    {
        name: "向上弹动",
        value: "bounce_up",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/bounce_up.webp',
        select: false,
    },
    {
        name: "向下弹动",
        value: "bounce_down",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/bounce_down.webp',
        select: false,
    },
    {
        name: "向右擦除",
        value: "wiperight",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/wiperight.webp',
        select: false,
    },
    // 
    {
        name: "向左擦除",
        value: "wipeleft",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/wipeleft.webp',
        select: false,
    },
    {
        name: "向下擦除",
        value: "wipedown",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/wipedown.webp',
        select: false,
    },
    {
        name: "向上擦除",
        value: "wipeup",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/wipeup.webp',
        select: false,
    },
    {
        name: "雪花消除",
        value: "morph",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/morph.webp',
        select: false,
    },
    {
        name: "色彩溶解",
        value: "colordistance",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/colordistance.webp',
        select: false,
    },
    // 
    {
        name: "圆形遮罩",
        value: "circlecrop",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/circlecrop.webp',
        select: false,
    },
    {
        name: "中心旋转",
        value: "swirl",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/swirl.webp',
        select: false,
    },
    {
        name: "轻微摇摆",
        value: "dreamy",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/dreamy.webp',
        select: false,
    },
    {
        name: "多格翻转",
        value: "gridflip",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/gridflip.webp',
        select: false,
    },
    {
        name: "圆形放大",
        value: "zoomincircles",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/zoomincircles.webp',
        select: false,
    },
    // 
    {
        name: "圆形扫描",
        value: "radial",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/radial.webp',
        select: false,
    },
    {
        name: "相册",
        value: "mosaic",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/mosaic.webp',
        select: false,
    },
    {
        name: "线性溶解",
        value: "crosshatch",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/crosshatch.webp',
        select: false,
    },
    {
        name: "太空波纹",
        value: "crazyparametricfun",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/crazyparametricfun.webp',
        select: false,
    },
    {
        name: "波形放大",
        value: "undulatingburnout",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/undulatingburnout.webp',
        select: false,
    },
    // 
    {
        name: "万花筒",
        value: "kaleidoscope",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/kaleidoscope.webp',
        select: false,
    },
    {
        name: "百叶窗",
        value: "windowblinds",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/windowblinds.webp',
        select: false,
    },
    {
        name: "蜂巢溶解",
        value: "hexagonalize",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/hexagonalize.webp',
        select: false,
    },
    {
        name: "故障交替",
        value: "glitchdisplace",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/glitchdisplace.webp',
        select: false,
    },
    {
        name: "炫境",
        value: "dreamyzoom",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/dreamyzoom.webp',
        select: false,
    },
    // 
    {
        name: "齿状上升",
        value: "doomscreentransition_up",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/doomscreentransition_up.webp',
        select: false,
    },
    {
        name: "齿状下落",
        value: "doomscreentransition_down",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/doomscreentransition_down.webp',
        select: false,
    },
    {
        name: "波纹",
        value: "ripple",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/ripple.webp',
        select: false,
    },
    {
        name: "风车",
        value: "pinwheel",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/pinwheel.webp',
        select: false,
    },
    {
        name: "时钟旋转",
        value: "angular",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/angular.webp',
        select: false,
    },
    // 
    {
        name: "燃烧",
        value: "burn",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/burn.webp',
        select: false,
    },
    {
        name: "椭圆遮罩",
        value: "circle",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/circle.webp',
        select: false,
    },
    {
        name: "椭圆溶解",
        value: "circleopen",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/circleopen.webp',
        select: false,
    },
    {
        name: "色相溶解",
        value: "colorphase",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/colorphase.webp',
        select: false,
    },
    {
        name: "隧道扭曲",
        value: "crosswarp",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/crosswarp.webp',
        select: false,
    },
    // 
    {
        name: "立方体",
        value: "cube",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/cube.webp',
        select: false,
    },
    {
        name: "渐变擦除",
        value: "directionalwipe",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/directionalwipe.webp',
        select: false,
    },
    {
        name: "开幕",
        value: "doorway",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/doorway.webp',
        select: false,
    },
    {
        name: "渐隐",
        value: "fade",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/fade.webp',
        select: false,
    },
    {
        name: "彩色渐隐",
        value: "fadecolor",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/fadecolor.webp',
        select: false,
    },
    // 
    {
        name: "灰色渐隐",
        value: "fadegrayscale",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/fadegrayscale.webp',
        select: false,
    },
    {
        name: "回忆",
        value: "flyeye",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/flyeye.webp',
        select: false,
    },
    {
        name: "爱心遮罩",
        value: "heart",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/heart.webp',
        select: false,
    },
    {
        name: "对角开幕",
        value: "luma",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/luma.webp',
        select: false,
    },
    {
        name: "多层混合",
        value: "multiplyblend",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/multiplyblend.webp',
        select: false,
    },
    // 
    {
        name: "像素溶解",
        value: "pixelize",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/pixelize.webp',
        select: false,
    },
    {
        name: "花瓣遮罩",
        value: "polarfunction",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/polarfunction.webp',
        select: false,
    },
    {
        name: "随机方块",
        value: "randomsquares",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/randomsquares.webp',
        select: false,
    },
    {
        name: "旋转",
        value: "rotatescalefade",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/rotatescalefade.webp',
        select: false,
    },
    {
        name: "方块替换",
        value: "squareswire",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/squareswire.webp',
        select: false,
    },
    // 
    {
        name: "向内推入",
        value: "squeeze",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/squeeze.webp',
        select: false,
    },
    {
        name: "切入",
        value: "swap",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/swap.webp',
        select: false,
    },
    {
        name: "线形擦除",
        value: "wind",
        img: 'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_pic/wind.webp',
        select: false,
    },
]