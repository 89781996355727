<style lang="less" scoped>
.strategy-content {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.hd {
    padding: 0 10px;
}
.sum-row {
    display: flex;
}
.sum-item {
    background-color: #f5f6f8;
    border-radius: 2px;
    line-height: 1;
    padding: 6px 10px;
    & + & {
        margin-left: 10px;
    }
}
.setting {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
}
.group_num {
    color: #8597b2;
}
.strategy-box {
    flex: 1;
    height: 0;
    overflow: hidden auto;
    display: flex;
    flex-wrap: wrap;
}
.strategy-list {
    width: 25%;
    min-width: 135px;
    padding: 6px;
    &:nth-child(n) .strategt-hd {
        background: linear-gradient(180deg, #299bff 45%, #76bfff 100%);
    }
    &:nth-child(2n) .strategt-hd {
        background: linear-gradient(360deg, #ffa9a9 0%, #ff6767 100%);
    }
    .strategt-hd {
        color: #fff;
        height: 32px;
        line-height: 32px;
        text-align: center;
        border-radius: 6px;
    }
    .info {
        padding: 10px;
        background-color: #f5f6f8;
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
        & > div {
            margin-top: 6px;
        }
    }
}
</style>
<template>
    <div class="strategy-content">
        <div class="hd">
            <div class="sum-row">
                <div class="sum-item">可生成脚本：0</div>
                <div class="sum-item">可生成视频：0</div>
                <div class="sum-item">时长：0s~10s</div>
            </div>
            <div class="setting">
                <div>已设定：固定分组顺序</div>
                <div class="group_num">
                    {{ get_group.length }}个分组，共{{ VIDEO_LEN }}个镜头
                </div>
            </div>
        </div>
        <div class="strategy-box">
            <div
                class="strategy-list"
                v-for="(item, index) in get_group"
                :key="index"
            >
                <div class="strategt-hd">
                    {{ item.title }}
                </div>
                <div class="info">
                    <div>
                        分组位置:
                        <span>固定#{{ index + 1 }}</span>
                    </div>
                    <div>
                        镜头模式:
                        <span v-if="item.setting.mix === 1">单次混剪</span>
                        <span v-else-if="item.setting.mix === 2">智能混剪</span>
                        <span v-else>单镜头</span>
                    </div>
                    <div>视频时长: 随机时长</div>
                    <div>
                        跟随音频:
                        <span v-if="item.setting.follow == 1">跟随</span>
                        <span v-else>不跟随</span>
                    </div>
                    <div>
                        剪裁视频:
                        <span v-if="item.setting.cropping == 1">去头</span>
                        <span v-else-if="item.setting.cropping == 2">去尾</span>
                        <span v-else-if="item.setting.cropping == 3"
                            >去头去尾</span
                        >
                        <span v-else>不裁剪</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "strategy",

    data() {
        return {};
    },
    computed: {
        ...mapGetters(["VIDEO_LEN"]),
        get_group() {
            return this.$store.getters.GET_GROUP;
        },
    },
    mounted() {},

    methods: {},
};
</script>

<style lang="scss" scoped>
</style>