<style lang="less" scoped>
.transition-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    .transition-title {
        flex: 0 0 40px;
        padding: 0 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .transition-box {
        flex: 1;
        height: 0;
        .transition-group {
            display: flex;
            height: 100%;
            flex-wrap: wrap;
            align-content: flex-start;
            overflow: hidden auto;
            .transition-item {
                flex: 0 0 20%;
                padding: 4px;
                width: 0;
                // box-sizing: border-box;
                .img-box {
                    line-height: 0;
                    border: 2px solid transparent;
                    border-radius: 4px;
                    overflow: hidden;
                    box-sizing: border-box;
                }
                &.active {
                    .img-box {
                        border-color: #349ffd;
                        box-shadow: 0 0 6px;
                    }
                    .name {
                        color: #349ffd;
                    }
                }
            }
        }
    }

    .transition-bottom {
        flex: 0 0 150px;
        display: flex;
        flex-direction: column;
        .timer-row {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 0;
        }
        .transition-set {
            background: linear-gradient(180deg, #f5f6f8 2%, #e8ecef 100%);
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px 0;
            display: flex;
            .volume-box {
                padding-right: 10px;
            }
            /deep/ .el-checkbox.is-bordered.el-checkbox--mini {
                padding-right: 8px;
            }
        }
        .fission-btn {
            flex: 1;
            height: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            .fission-submit {
                display: block;
                width: 126px;
                height: 25px;
                background: linear-gradient(302deg, #ff3737 0%, #349ffd 100%);
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
                border-radius: 2px;
                text-align: center;
                line-height: 25px;
                color: #fff;
            }
        }
    }
}
.name {
    text-align: center;
    color: #8597b2;
    line-height: 1.5;
    padding: 6px 0;
}
</style>
<style>
.volue {
    min-width: 64px;
}
</style>
<template>
    <div class="transition-content">
        <!-- {{ getGroupSet }} -->
        <div class="transition-title">
            <div>镜头分组转场</div>
            <!-- {{ GET_TRANSITIONG }} -->
            <div>
                所有分组同步设置
                <el-switch v-model="transition_fit" @change="set_transition">
                </el-switch>
            </div>
        </div>
        <div class="transition-box">
            <div class="transition-group">
                <div
                    class="transition-item"
                    @click="tab_select('')"
                    :class="GET_TRANSITIONG == '' ? 'active' : ''"
                >
                    <div class="img-box">
                        <el-image
                            style="width: 100%"
                            src="
                                https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_cover.jpg
                            "
                            fit="fill"
                        ></el-image>
                    </div>
                    <div class="name">无</div>
                </div>
                <div
                    class="transition-item"
                    v-for="(item, index) in transition"
                    :key="index"
                    @click="tab_select(item.referenceId)"
                    :class="GET_TRANSITIONG == item.referenceId ? 'active' : ''"
                >
                    <div class="img-box">
                        <el-image
                            style="width: 100%"
                            :src="
                                item.coverUrl ||
                                'https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/transition_cover.jpg'
                            "
                            fit="fill"
                        ></el-image>
                    </div>
                    <div class="name">
                        {{ item.name || item.fileName }}
                    </div>
                </div>
            </div>
        </div>
        <div class="transition-bottom">
            <div class="transition-timer-box">
                <div class="timer-row">
                    <div>转场时间</div>
                    <div>
                        <el-input-number
                            size="mini"
                            :min="0.1"
                            :max="5"
                            :step="0.1"
                            label="转场时间"
                            v-model="tranasition_timer"
                            @change="changTime"
                        ></el-input-number>
                    </div>
                </div>
            </div>

            <!-- 统一音量设置 -->
            <div class="transition-set">
                <!-- <div class="volume-box">
                    <el-checkbox
                        style="border: none"
                        v-model="checked"
                        border
                        size="mini"
                        >统一音量</el-checkbox
                    >
                    <el-popover
                        popper-class="volue"
                        placement="top"
                        trigger="click"
                        width="40px"
                    >
                        <i
                            slot="reference"
                            class="iconfont icon-yinliang"
                            style="
                                font-size: 12px;
                                color: #1890ff;
                                margin-right: 8px;
                            "
                        ></i>
                        <div class="">
                            <el-slider
                                v-model="value"
                                vertical
                                :max="300"
                                height="100px"
                            >
                            </el-slider>
                        </div>
                    </el-popover>
                    <i
                        class="iconfont icon-a-yiwen"
                        style="font-size: 12px; color: #ff7110"
                    ></i>
                </div> -->
                <div style="margin-right: 6px">生成数量</div>

                <el-select
                    size="mini"
                    style="width: 100px"
                    v-model="produce_num"
                    placeholder="请选择"
                >
                    <el-option label="不限产出" value="0"> </el-option>
                    <el-option label="10条" value="10"> </el-option>
                    <el-option label="50条" value="50"> </el-option>
                    <el-option label="100条" value="100"> </el-option>
                    <el-option label="300条" value="300"> </el-option>
                    <el-option label="500条" value="500"> </el-option>
                    <el-option label="1000条" value="1000"> </el-option>
                    <!-- <el-option label="自定义" value="100000"> </el-option> -->
                </el-select>
            </div>
            <!-- 裂变按钮 -->
            <div class="fission-btn">
                <a
                    href="javascript:;"
                    class="fission-submit"
                    @click="fission_submit"
                    >生成裂变</a
                >
            </div>
        </div>
    </div>
</template>

<script>
import Bus from "@/utils/eventBus";
import { duration } from "moment";
import { transition } from "@/assets/js/transition.js";
import { getTransitionImg } from "@/api/engi";
import { mapGetters } from "vuex";
// console.log(transition);
export default {
    name: "",

    data() {
        return {
            value: 0,
            select: "",
            transition_fit: 0,
            tranasition_timer: 0.5,
            fission_num: 300,
            checked: 0,
            selected: "",
            transition: [],
        };
    },
    computed: {
        ...mapGetters(["GET_TRANSITIONG"]),
        produce_num: {
            get() {
                return this.$store.getters.GET_FISS_COUNT;
            },
            set(val) {
                this.$store.commit("SET_FISS_COUNT", val);
            },
        },
    },
    mounted() {},
    created() {
        getTransitionImg({ directoryId: 4, size: 30 }).then((res) => {
            console.log(res);
            if (res.code == 200) {
                this.transition = res.data.records;
            } else {
                this.transition = transition;
            }
        });
    },
    methods: {
        changTime(val) {
            console.log(val);
            console.log("设置转场时长");
            // this.busTransition();
        },
        // 给脚本组件推送转场值
        tab_select(transition) {
            this.select = transition;
            this.set_transition();
        },
        set_transition() {
            let json = {
                transition: this.select,
                all_select: this.transition_fit,
                duration: this.tranasition_timer,
            };
            this.$store.commit("SET_GROUP_TRANSITION", json);
        },
        busTransition() {
            let json = {
                duration: this.tranasition_timer,
                transition: this.selected,
            };
            Bus.$emit("getTransition", json);
        },
        fission_submit() {
            // 给父组件发送提交请求
            this.$emit("submit", 1);
        },
    },
};
</script>

<style lang="scss" scoped>
</style>