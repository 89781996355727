<style lang="less" scoped>
.audio-box {
    display: flex;
    flex-direction: column;
    height: 100%;
    .audio-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
    }
    .audio-main {
        flex: 1;
        height: 0;
        overflow: hidden auto;
        padding: 0 10px;
        .audio-gruop {
            display: flex;
            flex-wrap: wrap;
            border-radius: 4px;
            &.change {
                border: 1px solid #e03e3e;
            }
            .audio-list {
                flex: 0 0 25%;
                width: 0;
                box-sizing: border-box;
                padding: 4px;
                position: relative;
                .file_name {
                    padding: 4px 0;
                }
                img {
                    width: 100%;
                }
                &:hover .icon-a-shanchu {
                    display: block;
                }
                .icon-a-shanchu {
                    display: none;
                    position: absolute;
                    right: 12px;
                    top: 12px;
                    background-color: rgba(0, 0, 0, 0.2);
                    color: #526c93;
                    font-size: 12px;
                    cursor: pointer;
                }
            }
        }
    }
}

.audio-btns {
    .iconfont {
        font-size: 12px;
        cursor: pointer;
        background-color: #f5f6f8;
        margin-right: 10px;
    }
    .icon-a-yiwen {
        color: #ff7110;
    }
}
.tis-conten {
    width: 260px;
}
</style>
<template>
    <div class="audio-box">
        <div class="audio-title">
            <div class="title">音频</div>
            <div class="audio-btns">
                <i class="iconfont icon-a-tianjia" @click="daoru"></i>
                <i class="iconfont icon-a-bianji" @click="changeBgm"></i>
                <!-- <i class="iconfont icon-a-shanchu"></i> -->
                <el-tooltip placement="top">
                    <div slot="content" class="tis-conten">
                        <div>通过添加多条音轨满足不同场景需求：</div>
                        <div>1、同时添加多条完整口播、多条BGM</div>
                        <div>2、系统为视频组合智能循环匹配口播、BGM</div>
                        <div>
                            3、配置音频在特定时间、分组播放，如：实现
                            展开无声效果，视频播放的0~5秒无声音
                        </div>
                        <div>4、更多场景请大家积极探索</div>
                    </div>
                    <i class="iconfont icon-a-yiwen"></i>
                </el-tooltip>
            </div>
        </div>
        <div class="audio-main">
            <div
                class="audio-gruop"
                v-if="getBgm.length"
                :class="$store.state.select_fiss.group === -1 ? 'change' : ''"
                @click="changeBgm"
            >
                <div
                    class="audio-list mater-item"
                    v-for="(item, index) in getBgm"
                    :key="index"
                >
                    <i
                        class="iconfont icon-a-shanchu"
                        @click.stop="del_audio(item, index)"
                    ></i>
                    <img src="@/assets/images/yp.png" alt="" />
                    <div class="ellipsis file_name">
                        {{ item.fileName }}
                    </div>
                </div>
            </div>
            <div style="width: 100%" description="" v-else>
                <el-empty description="您还未添加音频呢 ^_^" :image-size="80">
                </el-empty>
            </div>
        </div>
    </div>
</template>

<script>
import Bus from "@/utils/eventBus";
// import SOURCE_MATERIAL from "@/components/work/sourceMaterial";
export default {
    name: "AudioTrack",
    data() {
        return {
            // drawer: false,
            backAudio: this.$store.state.bgm,
        };
    },

    mounted() {
        console.log(this.audio);
    },
    computed: {
        getBgm() {
            return this.$store.state.bgm;
        },
    },
    methods: {
        del_audio(data, index) {
            const h = this.$createElement;
            this.$msgbox({
                title: "提示",
                message: h("div", null, [
                    h("div", null, "此操作为移除BGM音频 "),
                    h(
                        "div",
                        { style: "color: teal;padding:10px 0" },
                        `文件名：${data.fileName}`
                    ),
                    h("div", null, "是否继续操作 ？"),
                ]),
                showCancelButton: true,
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                beforeClose: (action, instance, done) => {
                    if (action === "confirm") {
                        instance.confirmButtonLoading = true;
                        instance.confirmButtonText = "移除中...";
                        setTimeout(() => {
                            let json = {
                                index,
                                type: 1,
                            };
                            this.$store.commit("DEL_AUDIO", json);
                            done();
                            setTimeout(() => {
                                instance.confirmButtonLoading = false;
                            }, 100);
                        }, 500);
                    } else {
                        done();
                    }
                },
            });
        },
        daoru() {
            let json = {
                type: "audio",
                group: -1,
            };
            this.$store.commit("SET_FISS_SELECT", json);
            console.log("导入");
            Bus.$emit("daoru", json);
        },
        changeBgm() {
            let json = {
                type: "audio",
                group: -1,
            };
            this.$store.commit("SET_FISS_SELECT", json);
        },
    },
};
</script>

<style lang="scss" scoped>
</style>