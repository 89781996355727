<style lang="less" scoped>
/deep/ .el-progress__text {
    font-size: 12px !important;
}
.set-content .el-radio {
    width: auto;
}
.set-row {
    display: flex;
    margin-top: 10px;
    .set-item {
        background-color: #f0f8ff;
        border: 1px solid #bfe1ff;
        padding: 4px;
        border-radius: 2px;
        flex: 1;
        width: 100%;
        & + .set-item {
            margin-left: 10px;
        }
    }
}
.icon-a-yiwen {
    font-size: 13px;
    color: #ff7110;
}
.input-row {
    height: 18px;
    background-color: #fff;
    border-radius: 2px;
    color: #8597b2;
    display: flex;
    align-items: center;
    padding: 0 10px;
    box-sizing: border-box;
    .iconfont {
        font-size: 12px;
        transform: scale(0.8);
    }
}
.slider-box {
    flex: 1;
    padding: 0 4px;
    /deep/ .el-slider__runway,
    /deep/ .el-slider__bar {
        height: 2px;
    }
    /deep/ .el-slider__runway {
        margin: 8px 0;
    }
    /deep/ .el-slider__button {
        width: 4px;
        height: 4px;
        border-width: 1px;
        box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
    }
    /deep/ .el-slider__button-wrapper {
        width: 16px;
        height: 16px;
        top: -8px;
    }
}
.stepper_box {
    display: flex;
    .val-box {
        padding: 0 4px;
        .num {
        }
    }
    .stepper_btn {
        transform: scale(0.8);
        font-weight: bold;
        cursor: default;
    }
}
.audio_box {
    display: flex;
    overflow: auto hidden;
    .audio-list {
        flex: 0 0 104px;
        width: 0;
        position: relative;
        & + .audio-list {
            margin-left: 10px;
        }
        .file_name {
            padding: 4px 0;
        }
        img {
            height: 56px;
            width: 100%;
        }
        &:hover .icon-a-shanchu {
            display: block;
        }
        .icon-a-shanchu {
            display: none;
            position: absolute;
            right: 8px;
            top: 8px;
            background-color: rgba(0, 0, 0, 0.2);
            color: #526c93;
            font-size: 12px;
            cursor: pointer;
        }
    }
}
/deep/ .el-button {
    transform: scale(0.8);
}
.top {
    // max-height: 260px;
    overflow: hidden auto;
}
</style>
<template>
    <div class="set-content">
        <div>
            <div class="set-hd" v-if="$store.state.select_fiss.group > -1">
                <!-- {{ $store.state.select_fiss.group }} -->
                <div class="tab-box">
                    <TABS v-model="tab_index" :data="tab_list" />
                </div>
            </div>
            <div class="set-box">
                <div class="set-title">
                    <span
                        class=""
                        v-if="
                            $store.state.select_fiss.group > -1 &&
                            $store.state.select_fiss.group !== ''
                        "
                    >
                        {{
                            $store.getters.GET_GROUP[
                                $store.state.select_fiss.group
                            ].title
                        }}</span
                    >
                    <span v-else> BGM </span>

                    【设置】
                </div>
                <div
                    class="set-group"
                    v-if="$store.state.select_fiss.group > -1"
                >
                    <div v-if="tab_index == 1">
                        <div class="set-row">
                            <div class="set-item">
                                <div class="title">
                                    <div>组内音频</div>

                                    <div>
                                        <el-button
                                            type="primary"
                                            size="mini"
                                            plain
                                            @click="daoru"
                                        >
                                            <i class="el-icon-plus"></i>
                                            添加音频</el-button
                                        >
                                        <el-switch
                                            size="mini"
                                            v-model="synchronization"
                                        >
                                        </el-switch>
                                    </div>
                                </div>

                                <div
                                    class="audio_box"
                                    style="padding-bottom: 6px"
                                    v-if="getGroupAudio.length"
                                >
                                    <!-- {{ getGroupAudio }} -->
                                    <div
                                        class="audio-list mater-item"
                                        v-for="(item, index) in getGroupAudio"
                                        :key="index"
                                    >
                                        <i
                                            class="iconfont icon-a-shanchu"
                                            @click.stop="del_audio(item, index)"
                                        ></i>
                                        <img
                                            src="@/assets/images/yp.png"
                                            alt=""
                                        />
                                        <div class="ellipsis file_name">
                                            {{ item.fileName }}
                                        </div>
                                    </div>
                                </div>
                                <div v-else>暂无组内音频哦..</div>
                            </div>
                        </div>
                        <div class="set-row">
                            <div class="set-item">
                                <div class="title">组内音频音量</div>

                                <div class="input-row">
                                    <i class="iconfont icon-yinliang"></i>
                                    <div class="slider-box">
                                        <el-slider
                                            :max="volumeMax"
                                            v-model="group_volum"
                                            :show-tooltip="false"
                                        ></el-slider>
                                    </div>
                                    <div class="val">{{ group_volum }}%</div>
                                </div>
                            </div>
                        </div>
                        <div class="set-row">
                            <div class="set-item">
                                <div class="title">
                                    <div>
                                        镜头是否跟随音频变速
                                        <el-tooltip placement="top">
                                            <div
                                                slot="content"
                                                class="tis-conten"
                                            >
                                                <div>
                                                    <div>1.选择跟随变速</div>
                                                    <div>
                                                        组内视频会通过音频时长设置变速
                                                    </div>
                                                    <div>
                                                        (如组内视频比音频时长差异大，会导致预览卡顿)
                                                    </div>
                                                </div>
                                                <div>
                                                    <div>2.选择不跟随</div>
                                                    <div>
                                                        视频，音频按原速播放
                                                    </div>
                                                </div>
                                            </div>
                                            <i
                                                class="iconfont icon-a-yiwen"
                                            ></i>
                                        </el-tooltip>
                                    </div>
                                </div>
                                <div class="seted">
                                    <div class="set-mode">
                                        <el-radio-group
                                            v-model="follow"
                                            size="mini"
                                        >
                                            <el-radio :label="1" border
                                                >跟随</el-radio
                                            >
                                            <el-radio :label="2" border
                                                >不跟随</el-radio
                                            >
                                        </el-radio-group>
                                    </div>
                                </div>
                            </div>
                            <div class="set-item">
                                <div class="title">镜头分组状态</div>
                                <div class="seted">
                                    <div class="set-mode">
                                        <el-radio-group
                                            v-model="group_status"
                                            size="mini"
                                        >
                                            <el-radio :label="1" border
                                                >激活</el-radio
                                            >
                                            <el-radio :label="0" border
                                                >停用</el-radio
                                            >
                                        </el-radio-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="set-row">
                            <div class="set-item">
                                <div class="title">
                                    <div>
                                        裁剪视频

                                        <el-tooltip placement="top">
                                            <div
                                                slot="content"
                                                class="tis-conten"
                                            >
                                                <div>
                                                    <div>1.不裁剪</div>
                                                    <div>
                                                        视频，音频，原速播放
                                                    </div>
                                                </div>
                                                <div>
                                                    <div>2.去头</div>
                                                    <div>
                                                        将片头删除，保留片尾匹配音频时长
                                                    </div>
                                                </div>
                                                <div>
                                                    <div>3.去尾</div>
                                                    <div>
                                                        将片尾删除，保留片头匹配音频时长
                                                    </div>
                                                </div>
                                                <div>
                                                    <div>3.去尾</div>
                                                    <div>
                                                        将片尾，片尾删除，保留片中匹配音频时长
                                                    </div>
                                                </div>
                                            </div>
                                            <i
                                                class="iconfont icon-a-yiwen"
                                            ></i>
                                        </el-tooltip>
                                    </div>
                                </div>
                                <div class="seted">
                                    <div class="set-mode">
                                        <el-radio-group
                                            v-model="cropping"
                                            size="mini"
                                        >
                                            <el-radio :label="0" border
                                                >不裁剪</el-radio
                                            >
                                            <el-radio :label="1" border
                                                >去头</el-radio
                                            >
                                            <el-radio :label="2" border
                                                >去尾</el-radio
                                            >
                                            <el-radio :label="3" border
                                                >去头去尾</el-radio
                                            >
                                        </el-radio-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="groupp-set-box" v-else-if="tab_index == 0">
                        <div class="set-row">
                            <div class="set-item">
                                <div class="title">镜头分组出现位置</div>
                                <div class="seted">
                                    <div class="set-mode">
                                        <el-radio-group
                                            v-model="add_index"
                                            size="mini"
                                        >
                                            <el-radio label="0" border disabled
                                                >固定</el-radio
                                            >
                                            <el-radio label="1" border
                                                >随机</el-radio
                                            >
                                        </el-radio-group>
                                    </div>
                                </div>
                                <div class="set-group-add">
                                    <el-select
                                        v-model="group_add"
                                        placeholder="请选择"
                                        :disabled="add_index == 1"
                                        size="mini"
                                    >
                                        <el-option label="固定第一位" value="1">
                                        </el-option>
                                        <el-option label="固定第二位" value="2">
                                        </el-option>
                                    </el-select>
                                </div>
                            </div>
                            <div class="set-item">
                                <div
                                    class="title"
                                    style="justify-content: flex-start"
                                >
                                    镜头组合模式切换
                                    <el-tooltip placement="top">
                                        <div slot="content" class="tis-conten">
                                            <div>
                                                当前分组仅选择单个镜头参与与视频组合，
                                            </div>
                                            <div>生成更精准的视频组合。</div>
                                        </div>
                                        <i class="iconfont icon-a-yiwen"></i>
                                    </el-tooltip>
                                    <!-- <el-switch
                                v-model="mode_unify"
                                inactive-text="统一"
                            >
                            </el-switch> -->
                                </div>
                                <div class="mode-set">
                                    <!-- {{ getGroupSet }} -->
                                    <!--  -->
                                    <div class="mode-set-box">
                                        <ul class="mode-group">
                                            <li
                                                class="mode-item"
                                                :class="
                                                    getGroupSet.mix == index
                                                        ? 'active'
                                                        : ''
                                                "
                                                v-for="(
                                                    item, index
                                                ) in mode_select"
                                                :key="index"
                                                @click="mode_tab(index)"
                                            >
                                                <el-image
                                                    style="
                                                        width: 20px;
                                                        height: 20px;
                                                    "
                                                    :src="
                                                        getGroupSet.mix == index
                                                            ? item.select
                                                            : item.img
                                                    "
                                                    fit="fill"
                                                ></el-image>
                                                <div class="mode-text">
                                                    {{ item.label }}
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="set-row">
                            <div class="set-item">
                                <div
                                    class="title"
                                    style="justify-content: flex-start"
                                >
                                    按时长筛选组合
                                    <el-tooltip placement="top">
                                        <div slot="content" class="tis-conten">
                                            <div>
                                                开启后，可按设定时长的前后0.5s的范围筛选镜头组合
                                            </div>
                                            <div>
                                                例如：设定时长为5s,组合取值范围为4.5s~5.5s
                                            </div>
                                        </div>
                                        <i class="iconfont icon-a-yiwen"></i>
                                    </el-tooltip>
                                </div>

                                <div class="input-row">
                                    <div class="slider-box">
                                        <el-slider
                                            v-model="filter_timer"
                                            :min="10"
                                            :max="100"
                                            :step="1"
                                            @change="changeTimer"
                                            @input="move_timer"
                                            :show-tooltip="false"
                                        ></el-slider>
                                    </div>
                                    <div class="val">{{ filter_timer }}s</div>
                                </div>
                            </div>

                            <div class="set-item">
                                <div
                                    class="title"
                                    style="justify-content: flex-start"
                                >
                                    镜头分组变速
                                </div>

                                <div
                                    class="input-row"
                                    style="justify-content: space-between"
                                >
                                    <div class="">倍速</div>
                                    <div class="stepper_box">
                                        <i class="el-icon-plus stepper_btn"></i>
                                        <div class="val-box">
                                            <span class="num">1</span>x
                                        </div>
                                        <i
                                            class="el-icon-minus stepper_btn"
                                        ></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="set-row">
                            <div class="set-item">
                                <div class="title">
                                    镜头分组原生音频
                                    <div>
                                        所有分组同步设置<el-switch
                                            size="mini"
                                            v-model="synchronization"
                                            @change="set_syn_volume"
                                        >
                                        </el-switch>
                                    </div>
                                </div>

                                <div class="input-row">
                                    <i class="iconfont icon-yinliang"></i>
                                    <div class="slider-box">
                                        <el-slider
                                            :max="volumeMax"
                                            v-model="volum"
                                            :show-tooltip="false"
                                        ></el-slider>
                                    </div>
                                    <div class="val">{{ volum }}%</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="$store.state.select_fiss.group == -1">
                    <div class="set-row">
                        <div class="set-item">
                            <div class="title">音量</div>

                            <div class="input-row">
                                <i class="iconfont icon-yinliang"></i>
                                <div class="slider-box">
                                    <el-slider
                                        :max="volumeMax"
                                        v-model="bgm_volum"
                                        :show-tooltip="false"
                                    ></el-slider>
                                </div>
                                <div class="val">{{ bgm_volum }}%</div>
                            </div>
                        </div>
                    </div>
                    <div class="set-row">
                        <div class="set-item">
                            <div class="title">播放模式</div>
                            <div class="seted">
                                <div class="set-mode">
                                    <el-radio-group
                                        v-model="paly_add"
                                        size="mini"
                                    >
                                        <el-radio :label="1" border
                                            >延迟播放</el-radio
                                        >
                                        <el-radio :label="2" border
                                            >从选定组播放</el-radio
                                        >
                                    </el-radio-group>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="set-row">
                        <div class="set-item">
                            <div class="title">字幕识别</div>
                            <div class="seted">
                                <div class="set-mode">
                                    <el-radio-group
                                        v-model="identify"
                                        size="mini"
                                    >
                                        <el-radio :label="0" border
                                            >不识别</el-radio
                                        >
                                        <el-radio :label="1" border
                                            >识别当前音轨音频字幕</el-radio
                                        >
                                    </el-radio-group>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Bus from "@/utils/eventBus";
import TABS from "@/components/tabs";
export default {
    name: "",
    props: ["projectType", "volume"],
    components: { TABS },
    data() {
        return {
            volumeMax: 200,
            // bgm_volum: 50,
            tab_list: [
                {
                    name: "分组设置",
                    icon: "iconfont icon-a-shezhi",
                },
                {
                    name: "视频音效",
                    icon: "iconfont icon-a-shipinyinxiao",
                },
            ],
            // paly_add: 1,
            // identify: 0,
            intercept: "1", //1去头，2，去尾，3，去头去尾
            // follow: "1", //1，跟随，0，不跟随
            // group_status: "1", //0，停用，1启动
            audio: [],
            // volum: this.volume,
            // 镜头模式
            mode: this.projectType,
            // 镜头统一开关
            mode_unify: false,
            // 镜头选择index
            mode_index: this.$store.getter,
            add_index: "1",
            // tab控制
            tab_index: 0,
            group_add: 1,
            filter_timer: 1,
            synchronization: 0,
            speed: 1,
            // 组信息
            gruop: {
                gruop_index: "",
                gruop_name: "",
            },
            groupSet: {},
            // 镜头数据
            mode_select: [
                {
                    id: 0,
                    mode: "singleLensScissors",
                    label: "单镜头",
                    img: require("@/assets/images/fission/djt_1.png"),
                    select: require("@/assets/images/fission/djt_2.png"),
                },
                {
                    id: 1,
                    mode: "singleMixed",
                    label: "单次混剪",
                    img: require("@/assets/images/fission/dchj_1.png"),
                    select: require("@/assets/images/fission/dchj_2.png"),
                },
                {
                    id: 2,
                    mode: "smartMix",
                    label: "智能混剪",
                    img: require("@/assets/images/fission/znhj_1.png"),
                    select: require("@/assets/images/fission/znhj_2.png"),
                },
            ],
        };
    },
    computed: {
        getGroupAudio() {
            let groupAudio = this.$store.getters.GET_GROUP_AUDIO;
            return groupAudio;
            // console.log(groupAudio);
        },
        getGroupSet() {
            let groupSet = this.$store.getters.GET_GROUP_SET;
            // console.log(groupSet);
            // this.groupSet = groupSet;
            return groupSet;
        },
        cropping: {
            get() {
                // console.log(1);
                return this.$store.getters.GET_GROUP_SET.cropping;
            },
            set(val) {
                this.$store.commit("SET_CROPPING", val);
            },
        },
        follow: {
            get() {
                return this.$store.getters.GET_GROUP_SET.follow;
            },
            set(val) {
                this.$store.commit("SET_FOLLOW", val);
            },
        },
        group_status: {
            get() {
                return this.$store.getters.GET_GROUP_SET.group_status;
            },
            set(val) {
                this.$store.commit("SET_GROUP_STATUS", val);
            },
        },
        volum: {
            get() {
                return this.$store.getters.GET_GROUP_SET.volume;
            },
            set(val) {
                let setData = {
                    isSyn: this.synchronization,
                    val,
                };

                this.$store.commit("SET_VOLUME", setData);
            },
        },
        group_volum: {
            get() {
                return this.$store.getters.GET_GROUP_SET.group_volume;
            },
            set(val) {
                this.$store.commit("SET_GROUP_VOLUME", val);
            },
        },
        bgm_volum: {
            get() {
                return this.$store.getters.GET_BGM_SET.volume;
            },
            set(val) {
                this.$store.commit("SET_BGM_VOLUME", val);
            },
        },
        paly_add: {
            get() {
                return this.$store.getters.GET_BGM_SET.paly_add;
            },
            set(val) {
                this.$store.commit("SET_BGM_PLAY_ADD", val);
            },
        },
        identify: {
            get() {
                return this.$store.getters.GET_BGM_SET.identify;
            },
            set(val) {
                this.$store.commit("SET_BGM_IDENTIFY", val);
            },
        },
    },
    mounted() {},
    created() {
        Bus.$on("gruopIndex", (res) => {
            this.gruop = res;
            this.mode_index = res.mode_id;
        });
        Bus.$on("audio", (res) => {
            console.log(res);
            this.audio = res;
        });
    },
    methods: {
        del_audio(data, index) {
            const h = this.$createElement;
            this.$msgbox({
                title: "提示",
                message: h("div", null, [
                    h("div", null, "此操作为移除组内音频 "),
                    h(
                        "div",
                        { style: "color: teal;padding:10px 0" },
                        `文件名：${data.fileName}`
                    ),
                    h("div", null, "是否继续操作 ？"),
                ]),
                showCancelButton: true,
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                beforeClose: (action, instance, done) => {
                    if (action === "confirm") {
                        instance.confirmButtonLoading = true;
                        instance.confirmButtonText = "移除中...";
                        setTimeout(() => {
                            let json = {
                                index,
                                type: 2,
                            };
                            this.$store.commit("DEL_AUDIO", json);
                            done();
                            setTimeout(() => {
                                instance.confirmButtonLoading = false;
                            }, 100);
                        }, 500);
                    } else {
                        done();
                    }
                },
            });
        },
        move_vol(val) {
            this.volum = val;
            // console.log(val);
        },
        changeVolume(volume) {
            this.$emit("volume", volume);
        },
        move_timer(val) {
            this.filter_timer = val;
        },
        changeTimer(val) {
            console.log("时长筛选：" + val);
        },
        set_syn_volume(val) {
            // get() {
            //     return this.$store.getters.GET_GROUP_SET.volume;
            // },
            // set(val) {
            //     let setData = {
            //         isSyn: this.synchronization,
            //         val,
            //     };
            //     this.$store.commit("SET_VOLUME", setData);
            // },
            if (val) {
                let volume = this.$store.getters.GET_GROUP_SET.volume;
                let setData = {
                    isSyn: val,
                    val: volume,
                };
                this.$store.commit("SET_VOLUME", setData);
            }
        },
        mode_tab(index) {
            // console.log(index);

            if (index == this.$store.getters.GET_GROUP_SET.mix) return;
            if (index === 0) {
                // 单镜头
                this.$confirm(
                    "设置单镜头模式，其他模式将会失效, 是否继续?",
                    "提示",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                    }
                ).then(() => {
                    let mode = {
                        all_select: true,
                        val: index,
                    };
                    this.$store.commit("SET_GROUP_MIX", mode);
                });
            } else {
                let mode = {
                    all_select: this.mode_unify,
                    val: index,
                };
                this.$store.commit("SET_GROUP_MIX", mode);
            }
        },
        // 给脚本组件通讯设置数据
        // changeMode() {
        //     let json = {
        //         isUnify: this.mode_unify,
        //         mode: this.mode_select[this.mode_index],
        //     };
        //     // 给脚本组件传送当前设置值
        //     Bus.$emit("groupSet", json);
        // },
        daoru() {
            if (this.$store.state.select_fiss.group < 0) {
                this.$message.error("请先选择视频组才能设置组内音频哦 ^_^");
                return;
            }
            let inop_data = {
                type: "audio",
            };
            this.$store.commit("SET_FISS_SELECT", inop_data);
            Bus.$emit("daoru", inop_data);
        },
    },
};
</script>

<style lang="less" scoped>
.audio-main {
    overflow: auto hidden;
    .audio-gruop {
        display: flex;
        flex-wrap: wrap;
        .audio-list {
            flex: 0 0 33.333%;
            box-sizing: border-box;
            padding: 4px;
            height: 80px;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
/deep/ .el-radio {
    margin-right: 0;
    width: 72px;
    padding: 3px 8px 0 8px;
    height: 22px;
}
.title {
    padding: 4px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.set-content {
    padding: 10px;
}
.set-hd {
    display: flex;
    justify-content: center;
    .iconfont {
        font-size: 12px;
    }
    .active {
        color: #349ffd;
    }
}
.set-title {
    background-color: #f5f6f8;
    text-align: center;
    padding: 4px 0;
    margin: 0 -10px;
    margin-top: 10px;
}
.set-group-add {
    margin-top: 8px;
}
.set-box {
    .groupp-set-box {
        // display: flex;
        .group-set-left,
        .group-set-right {
            flex: 1;
            width: 0;
            display: flex;
            padding: 0 10px;
            flex-direction: column;
            .top {
                flex: 0 0 110px;
            }
        }
    }
}
.set-row {
    display: flex;
    justify-content: space-between;

    .timer-set {
        flex: 0 0 80px;
        display: flex;
        justify-content: space-between;
        border: 1px solid #f3f3f3;
        padding: 3px 10px;
        i {
            color: #8597b2;
            cursor: pointer;
        }
    }
}
.mode-group {
    display: flex;
    padding: 4px;
    border: 1px solid #349ffd;
    border-radius: 4px;
    text-align: center;
    .mode-item {
        padding: 10px 0;
        cursor: default;
        .mode-text {
            transform: scale(0.8);
        }
        &.active {
            color: #fff;
            background-size: 100% 100%;
        }
        &:nth-child(1).active {
            // background-image: url("~/assets/images/fission/djt_bg");
            background-image: url("../../../assets/images/fission/djt_bg.png");
        }
        &:nth-child(2).active {
            // background-image: url("~/assets/images/fission/djt_bg");
            background-image: url("../../../assets/images/fission/dchj_bg.png");
        }
        &:nth-child(3).active {
            // background-image: url("~/assets/images/fission/djt_bg");
            background-image: url("../../../assets/images/fission/znhj_bg.png");
        }
        flex: 1;
    }
}
.frequency-row {
    border: 1px solid #ced4db;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 6px;
    border-radius: 4px;
    .frequency-progress {
        flex: 1;
        padding-left: 10px;
    }
}
</style>