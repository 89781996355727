<style lang="less">
.video_box {
    min-width: 0 !important;
    width: 178px !important;
    height: 128px !important;
    box-sizing: border-box;
}
.el-switch__core {
    width: 24px !important;
    height: 14px;
}
.el-switch__core::after {
    width: 12px;
    height: 12px;
    margin-top: -1px;
}
.el-switch.is-checked .el-switch__core::after {
    margin-left: -13px;
}
.el-switch__label * {
    font-size: 12px;
}
.tab-box {
    padding: 3px;
    background-color: #f5f6f8;
    .tab-group {
        display: flex;
        .tab-list {
            // flex: 1;
            padding: 4px 10px;
            cursor: pointer;
            &.active {
                background-color: #fff;
            }
        }
    }
}
.source-conten {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.source-box {
    flex: 1;
    height: 0;
}
.source-btn-box {
    display: flex;
    justify-content: right;
    padding: 10px 20px;
}
</style>
<style lang="less" scoped>
@bg_color: #fff;
.h_100 {
    height: 100%;
    position: relative;
}
.empty_box {
    display: none;
}
.not_select {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 99;
    .empty_data {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.fission-main {
    margin: 0 -20px;
    font-size: 12px;
    .fission-box {
        display: flex;
        .fission-left {
            flex: 1;
            min-width: 500px;
            // flex: 1;
            background-color: @bg_color;
        }
        .fission-cente {
            flex: 1;
            min-width: 400px;
            width: 0;
            padding: 0 20px;
            .main {
                display: flex;
                flex-direction: column;
                .top {
                    flex: 0 0 380px;
                    height: 0;
                    .set-box {
                    }
                }
                .preview-content {
                    flex: 1;
                    height: 0;
                    background-color: @bg_color;
                    display: flex;
                    flex-direction: column;
                    .preview-box {
                        flex: 1;
                        height: 0;
                        padding: 10px;
                        box-sizing: border-box;
                    }
                }
                .bottom {
                    padding-top: 20px;
                    flex: 0 0 260px;
                    height: 0;
                    .bottom-content {
                        background-color: @bg_color;
                        height: 100%;
                        .fission-box {
                            display: flex;
                            flex-direction: column;
                            height: 100%;
                            .fission-hd {
                                width: 100%;
                                display: flex;
                                justify-content: space-between;
                                padding: 10px 4px;
                                box-sizing: border-box;
                            }
                            .fission-body {
                                flex: 1;
                                height: 0;
                                overflow: hidden auto;
                            }
                        }
                    }
                }
            }
        }
        .fission-right {
            // flex: 0 0 370px;
            width: 370px;
            // width: 0;
            min-width: 370px;
            max-width: 600px;
            flex: 1;
            .set-box {
                height: 100%;
                display: flex;
                flex-direction: column;
                .top {
                    flex: 0 0 40%;
                    max-height: 350px;
                    overflow: hidden auto;
                    background-color: @bg_color;
                }
                .bottom {
                    padding-top: 20px;
                    flex: 1;
                    height: 0;
                    &-content {
                        height: 100%;
                        background-color: @bg_color;
                    }
                }
            }
        }
    }
}
.fission-row {
    display: flex;
    /deep/ .el-image__inner {
        border-radius: 8px;
    }
    .fission-item {
        position: relative;
        font-size: 12px;
        .fission_num {
            position: absolute;
            right: 3px;
            top: 8px;
            background-color: #fff;
            border-radius: 50%;
            width: 26px;
            height: 26px;
            line-height: 26px;
            text-align: center;
            z-index: 99;
            transform: scale(0.7);
        }

        .super_tag {
            position: absolute;
            top: 8px;
            left: 3px;
            background: linear-gradient(to right, #ff7373, #ffbdd3);
            color: #fff;
            z-index: 99;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
            padding: 2px 10px 2px;
            transform-origin: left;
            transform: scale(0.8);
        }
        .fission_txt {
            position: absolute;
            left: 4px;
            right: 4px;
            bottom: 4px;
            background-color: rgba(0, 0, 0, 0.3);
            color: #fff;
            z-index: 99;
            text-align: center;
        }
    }
    &.active {
        .fission-face {
            .fission-item {
                border: 1px solid #ff7373;
            }
        }
        // .fission-group {
        //     border: 1px solid #ff7373;
        // }
    }
    .fission-face {
    }
    .fission-group {
        flex: 1;
        width: 0;
        display: flex;
        overflow: auto hidden;
    }
    .fission-item {
        width: 134px;
        flex: 0 0 134px;
        height: 86px;
        padding: 4px;
        box-sizing: border-box;
        border-radius: 8px;
    }
}
.tabs_box {
    display: flex;
    justify-content: center;
    padding: 10px 0;
}
.poject_load_zhe {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 998;
}
.poject_load {
    width: 500px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    z-index: 999;
    padding: 28px;
    box-shadow: 0 0 35px #d0cdcd;
    border-radius: 6px;
}
.poject_tis {
    display: flex;
    align-items: center;
    margin-bottom: 6px;

    .load_num {
        width: 55px;
        font-size: 22px;
        color: #349ffd;
    }
    .load_txt {
        width: 0;
        flex: 1;
        // transform: translateY(-1px);
        padding: 0 10px;
    }
    .load_img {
        flex: 0 0 60px;
        height: 18px;
        position: relative;
        img {
            width: 20px;
            position: absolute;
            left: 0;
            top: 0;
            &:nth-child(1) {
                animation: img1 1s infinite linear;
            }
            &:nth-child(2) {
                animation: img2 1s infinite alternate;
            }
            &:nth-child(3) {
                animation: img3 1s infinite linear;
            }
        }
    }
    @keyframes img1 {
        0% {
            transform: rotate(90deg);
        }
        33% {
            transform: rotate(180deg);
        }
        66% {
            transform: rotate(270deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes img2 {
        0% {
            transform: rotate(-90deg);
        }
        33% {
            transform: rotate(-180deg);
        }
        66% {
            transform: rotate(-270deg);
        }
        100% {
            transform: rotate(-360deg);
        }
    }
    @keyframes img3 {
        0% {
            transform: rotate(45deg);
        }
        33% {
            transform: rotate(135deg);
        }
        66% {
            transform: rotate(225deg);
        }
        100% {
            transform: rotate(315deg);
        }
    }
}
</style>
<template>
    <div style="height: 100%">
        <!-- <div>
            <p>{{ $store.state.select_fiss }}</p>
        </div> -->

        <div class="fission-main h_100">
            <div class="fission-box h_100">
                <!-- 左 -->
                <div class="fission-left h_100">
                    <MATERIAL ref="material" @daoru="daoru" />
                </div>
                <!-- 中 -->
                <div class="fission-cente h_100">
                    <div
                        class="empty_box"
                        :class="
                            $store.state.select_fiss.group !== ''
                                ? ''
                                : 'not_select'
                        "
                    >
                        <div class="empty_data">
                            请先选择分组{{ $store.state.select_fiss.group }}
                        </div>
                    </div>
                    <!-- {{ $store.state.select_fiss.group }} -->
                    <div class="main h_100">
                        <div class="preview-content">
                            <div class="tabs_box">
                                <TABS v-model="active" :data="tab_list" />
                            </div>
                            <div class="preview-box">
                                <div class="h_100" v-if="active == 1">
                                    <PLAY_BOX
                                        ref="play1"
                                        :timeline="timeLine"
                                        :isLoading="isLoading"
                                    />
                                </div>
                                <div v-else class="h_100">
                                    <STRATEGY />
                                </div>
                            </div>
                        </div>
                        <div class="bottom">
                            <div class="bottom-content">
                                <div class="fission-box">
                                    <div class="fission-hd">
                                        <div class="select-box">
                                            <el-select
                                                v-model="value"
                                                placeholder="请选择"
                                                size="mini"
                                                style="width: 88px"
                                            >
                                                <el-option
                                                    label="全部脚本"
                                                    value="2"
                                                >
                                                </el-option>
                                                <el-option
                                                    label="脚本1"
                                                    value="1"
                                                >
                                                </el-option>
                                            </el-select>
                                            <el-select
                                                v-model="value"
                                                placeholder="请选择"
                                                size="mini"
                                                style="width: 88px"
                                            >
                                                <el-option
                                                    label="全部音频"
                                                    value="2"
                                                >
                                                </el-option>
                                                <el-option
                                                    label="音频一"
                                                    value="1"
                                                >
                                                </el-option>
                                            </el-select>
                                            <el-select
                                                v-model="value"
                                                placeholder="请选择"
                                                size="mini"
                                                style="width: 88px"
                                            >
                                                <el-option
                                                    label="全部时长"
                                                    value="1"
                                                >
                                                </el-option>
                                                <el-option
                                                    label="< 30"
                                                    value="2"
                                                >
                                                </el-option>
                                                <el-option
                                                    label="> 30"
                                                    value="3"
                                                >
                                                </el-option>
                                            </el-select>
                                        </div>
                                        <div>
                                            <el-button
                                                type="primary"
                                                size="mini"
                                                @click="openMenu"
                                                >查看全部</el-button
                                            >
                                            <!-- <el-button
                                                type="primary"
                                                size="mini"
                                                @click="getTimeLine"
                                                >预览</el-button
                                            > -->
                                        </div>
                                    </div>
                                    <!-- {{ preview }} -->
                                    <div class="fission-body">
                                        <div
                                            class="fission-row"
                                            v-for="(item, index) in preview"
                                            :class="
                                                item.selected ? 'active' : ''
                                            "
                                            :key="index"
                                        >
                                            <div class="fission-face">
                                                <!-- <div
                                                    class="fission-item"
                                                    title="点击选择分组"
                                                    @click="
                                                        getTimeLine(
                                                            item.id
                                                        )
                                                    "
                                                > -->
                                                <div
                                                    class="fission-item"
                                                    title="点击选择分组"
                                                    @click="setPlay(item)"
                                                >
                                                    <div
                                                        class="super_tag"
                                                        v-if="
                                                            item.superRecommendation ==
                                                            1
                                                        "
                                                    >
                                                        超级推荐
                                                    </div>
                                                    <div class="fission_num">
                                                        {{ index + 1 }}
                                                    </div>
                                                    <el-image
                                                        style="
                                                            width: 100%;
                                                            height: 100%;
                                                            background-color: #ddd;
                                                        "
                                                        :src="
                                                            $store.state.pic +
                                                            item.videos[0]
                                                                .coverUrl
                                                        "
                                                        fit="contain"
                                                    ></el-image>
                                                </div>
                                            </div>
                                            <!-- <div>{{ item.videos[0] }}</div> -->
                                            <div
                                                class="fission-group"
                                                title="点击选择分组"
                                                @click="selected(index)"
                                            >
                                                <div
                                                    class="fission-item"
                                                    v-for="(
                                                        k, i
                                                    ) in item.videos"
                                                    :key="i"
                                                >
                                                    <div class="fission_txt">
                                                        {{ k.sourceGroup }}
                                                        -{{ k.sourceSort }}
                                                    </div>
                                                    <el-image
                                                        style="
                                                            width: 100%;
                                                            height: 100%;
                                                            background-color: #ddd;
                                                        "
                                                        :src="
                                                            $store.state.pic +
                                                            k.coverUrl
                                                        "
                                                        fit="contain"
                                                    ></el-image>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 右 -->
                <div class="h_100 fission-right">
                    <div
                        class="empty_box"
                        :class="
                            $store.state.select_fiss.group !== ''
                                ? ''
                                : 'not_select'
                        "
                    >
                        <div class="empty_data">请先选择分组</div>
                    </div>
                    <!-- <div class="fission-right h_100"> -->
                    <div class="set-box">
                        <div class="top">
                            <GROUP_SET
                                @volume="getVolume"
                                :volume="senData.script.scriptClipsParam.volume"
                                :projectType="senData.projectType"
                                @getPtype="getPtype"
                            />
                        </div>
                        <div class="bottom">
                            <div class="bottom-content">
                                <TRANSITION @submit="submit" />
                            </div>
                        </div>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
        <div class="poject_load_zhe" v-show="show_poject_load"></div>
        <div class="poject_load" v-show="show_poject_load">
            <div class="poject_tis">
                <div class="load_num">{{ progress / 10 }}%</div>
                <div class="load_txt ellipsis">
                    {{ load_txts[load_text_index].txt }}
                </div>
                <div class="load_img">
                    <img
                        src="https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/fiss_load.png"
                        alt=""
                    />
                    <img
                        src="https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/fiss_load.png"
                        alt=""
                    />
                    <img
                        src="https://hotkeypic.oss-cn-shanghai.aliyuncs.com/hotkey/fiss_load.png"
                        alt=""
                    />
                </div>
            </div>
            <PROGRESS :style="{ height: '14px' }" />
        </div>
        <!-- 导入 -->
        <el-drawer
            :with-header="false"
            :destroy-on-close="true"
            size="80%"
            :append-to-body="true"
            :before-close="
                () => {
                    drawer = false;
                }
            "
            :visible.sync="drawer"
        >
            <div class="source-conten">
                <div class="source-box">
                    <SOURCE_MATERIAL ref="daoru" :sourec_type="sourec_type" />
                </div>

                <div slot="footer" class="source-btn-box">
                    <el-button @click="drawer = false" size="mini"
                        >取 消</el-button
                    >
                    <el-button type="primary" @click="add_source" size="mini"
                        >确 定</el-button
                    >
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import Bus from "@/utils/eventBus";
import SOURCE_MATERIAL from "@/components/work/sourceMaterial";
import MATERIAL from "@/components/edit/material/script.vue";
import PLAY_BOX from "@/components/edit/material/play_box.vue";
import STRATEGY from "@/components/edit/material/strategy.vue";
import GROUP_SET from "@/components/edit/material/group_set.vue";
import TRANSITION from "@/components/edit/material/transition.vue";
import PROGRESS from "@/components/progress";

import TABS from "@/components/tabs";
import {
    getFission,
    gitFission,
    getTimeLine,
    saveFissSet,
    getProje,
    getPreviewList,
    getProjectInfo,
} from "@/api/engi.js";
import { getScriptInfo } from "@/api/script";
export default {
    components: {
        MATERIAL,
        PLAY_BOX,
        GROUP_SET,
        TRANSITION,
        SOURCE_MATERIAL,
        STRATEGY,
        TABS,
        PROGRESS,
    },
    data() {
        return {
            active: 0,
            tab_list: [
                {
                    name: "策略预览",
                },
                {
                    name: "视频预览",
                },
            ],
            drawer: false,
            sourec_type: 1,
            activeName: 0,
            // 工程ID
            projectid: "",
            sid: "",
            token: sessionStorage.token,
            address: [
                {
                    value: "选项1",
                    label: "固定第1个",
                },
                {
                    value: "选项2",
                    label: "固定第2个",
                },
                {
                    value: "选项3",
                    label: "固定第3个",
                },
                {
                    value: "选项4",
                    label: "固定第4个",
                },
                {
                    value: "选项5",
                    label: "固定第5个",
                },
            ],
            value: "",
            time: "",
            imageUrl: "",
            videoLink: "",
            dialogVisible: false,

            //   上传目录
            showMenu: false,
            //   预览列表
            preview: [],
            scriptInfo: {
                id: "1622867019906985985",
                projectType: "singleLensScissors",
                scriptClass: "GenericScript",
                title: "test",
                canvas: { width: 16, height: 9 },
                rate: { x: 1080, y: 1920 },
                lensGroup: [],
            },
            // 后台数据裂变模板
            senData: {
                intputCount: "1000",
                projectType: "singleLensScissors",
                frontScriptInfo: "",
                script: {
                    Title: "人文风景视频单镜头混剪01147",
                    Description: "人文风景视频单镜头混剪01147",
                    Action: "CreateEditingProject",
                    scriptTemplateId: "1620341956125163522",
                    scriptClipsParam: {
                        title: "脚本TEST2",
                        scriptClass: "GenericScript",
                        canvas: '{"width":16,"height":9}',
                        rate: '{"x":1080,"y":1920}',
                        filter: "筛选合成时长",
                        volume: 50,
                        variableSpeed: "视频/音频同步变速",
                        backGroundAudio: {
                            backGroundList: [],
                        },
                        group: [],
                    },
                },
            },
            //   保存裂变数据
            sendFiss: {
                projectId: "",
                directoryId: "",
                delete: 0,
                end: "10",
                group: [],
            },
            // 获取timeline
            timeLine: "",
            preview_data: {
                projectId: "",
                delete: 0,
                end: "10",
                group: [],
            },
            isLoading: false,
            load_txts: [
                {
                    txt: "算法正在解构分析分镜头...",
                    delay: 2,
                },
                {
                    txt: "正在进行相似性、差异度、脚本结构等镜头分析...",
                    delay: 5,
                },
                {
                    txt: "正在智能生成高品质视频，并形成推荐标签...",
                    delay: 8,
                },
            ],
            load_text_index: 0, //当前进度文字
            load_timer: null, //进度定时器
            progress: 0, //裂变进度
            progress_timer: "", //裂变定时器
            show_poject_load: false,
        };
    },
    created() {
        Bus.$on("save", this.saveProject);
        let sid = this.$route.query.sid;
        let projId = this.$route.query.projId;
        if (sid) {
            this.sid = sid;
            this.get_sid(sid);
        }
        if (projId) {
            this.projectid = projId;
            getProjectInfo(projId).then((res) => {
                let project_data = JSON.parse(res.data.script.scriptClipsParam);
                console.log(project_data);
                // project_data.scriptClipsParam = JSON.parse(
                //     project_data.scriptClipsParam
                // );
                try {
                    project_data.canvas = JSON.parse(project_data.canvas);
                } catch (error) {
                    console.log(project_data.canvas);
                }

                try {
                    project_data.rate = JSON.parse(project_data.rate);
                } catch (error) {
                    console.log(error);
                }

                this.$store.commit("RESET_FISS", project_data);
                this.$store.commit("SET_FISS_ID", projId);
                this.getPreviewList();
            });

            // getProje({ id: projId }).then((res) => {
            //     let project_data = res.data.records[0];
            //     project_data.scriptClipsParam = JSON.parse(
            //         project_data.scriptClipsParam
            //     );
            //     project_data.scriptClipsParam.canvas = JSON.parse(
            //         project_data.scriptClipsParam.canvas
            //     );
            //     project_data.scriptClipsParam.rate = JSON.parse(
            //         project_data.scriptClipsParam.rate
            //     );

            //     this.$store.commit("RESET_FISS", project_data);
            //     this.$store.commit("SET_FISS_ID", projId);
            //     this.getPreviewList();
            // });
        }
        let _this = this;
        Bus.$on("daoruBack", function (res) {
            console.log(res);
            let backAudio =
                _this.senData.script.scriptClipsParam.backGroundAudio
                    .backGroundList;
            let audio = [...res, ...backAudio];
            _this.senData.script.scriptClipsParam.backGroundAudio.backGroundList =
                audio;
        });
        this.getPreviewList();
    },
    mounted() {
        // 监听后代导入指令
        Bus.$on("daoru", (res) => {
            this.daoru(res);
        });
    },
    beforeDestroy() {
        console.log("离开editing");
        Bus.$off();
    },
    methods: {
        progress_Interval() {
            let _this = this;
            let timer_num = 13;
            clearInterval(_this.progress_timer);
            _this.progress_timer = "";
            let progress = 0;
            // console.log(_this.progress);
            _this.progress_timer = setInterval(() => {
                // console.log(progress);
                progress = _this.progress + timer_num;
                if (progress > 1000) {
                    _this.progress = 1000;
                    clearInterval(_this.progress_timer);
                    setTimeout(() => {
                        _this.show_poject_load = false;
                    }, 500);
                } else {
                    // console.log(progress);
                    _this.progress = Math.floor(progress * 10) / 10;
                    // console.log(_this.progress);
                }
            }, 10);
        },
        loading() {
            let _this = this;
            setTimeout(function () {
                if (++_this.load_text_index >= 2) {
                    return;
                } else {
                    _this.loading();
                }
            }, this.load_txts[this.load_text_index].delay * 1000);
            this.progress_Interval();
        },
        add_source() {
            let ref_data = this.$refs.daoru.select_data;
            // console.log(ref_data);
            this.$store.commit("SET_FISS", ref_data);
            this.drawer = false;
        },
        // 导入素材
        daoru(selected) {
            let fiss_info = this.$store.state.select_fiss;
            this.sourec_type = fiss_info.type == "video" ? 1 : 2;
            this.drawer = true;
        },
        getVolume(volume) {
            console.log(volume);
            this.senData.script.scriptClipsParam.volume = volume;
        },
        getPtype(type) {
            this.senData.projectType = type;
        },
        generate_data() {
            return new Promise((resolve, reject) => {
                let data = this.$store.state.fiss_data;
                console.log(data);
                data.id = this.$store.state.fiss_id;
                let getGroup = this.$store.getters.GET_GROUP;
                let group = [];
                getGroup.map((item) => {
                    let list = {
                        title: "", //分组命名
                        txt: "",
                        videocreationFile: "", //视频列表
                        mouthAudio: {
                            volume: "",
                            mouthAudioList: "", //组内音频列表
                        },
                    };
                    list.title = item.title;
                    list.txt = item.txt;
                    list.setting = item.setting;
                    list.videocreationFile = item.video;
                    list.mouthAudio.mouthAudioList = item.audio;
                    group.push(list);
                });
                let bgm = {
                    backGroundList: this.$store.getters.GET_BGM,
                    ...this.$store.getters.GET_BGM_SET,
                };
                data.script.scriptClipsParam.backGroundAudio = bgm;
                data.script.scriptClipsParam.group = group;
                resolve(data);
            });
        },
        // 保存工程
        async saveProject() {
            let loading = this.$loading({
                lock: true,
                text: "正在保存配置信息",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            // 获得裂变数据
            const data = await this.generate_data();
            if (!data.id) {
                this.$prompt("请输入工程名字", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                })
                    .then(async ({ value }) => {
                        data.script.title = value;
                        console.log(data);

                        var res = await saveFissSet(data);
                        loading.close();
                        if (res.code == 200) {
                            this.$notify.success("工程配置保存成功");
                        } else {
                            this.$notify.error("工程配置保存失败");
                        }
                    })
                    .catch(() => {});
            } else {
                var res = await saveFissSet(data);
                loading.close();
                if (res.code == 200) {
                    this.$notify.success("工程配置保存成功");
                } else {
                    this.$notify.error("工程配置保存失败");
                }
            }
            // console.log(data);
            // 调用保存接口
            // loading.close();
        },

        // 生成裂变
        async submit() {
            // let data = this.$store.state.fiss_data;
            // data.id = this.$store.state.fiss_id;
            // let getGroup = this.$store.getters.GET_GROUP;
            // let group = [];
            // getGroup.map((item) => {
            //     let list = {
            //         title: "", //分组命名
            //         txt: "",
            //         videocreationFile: "", //视频列表
            //         mouthAudio: {
            //             volume: "",
            //             mouthAudioList: "", //组内音频列表
            //         },
            //     };
            //     list.title = item.title;
            //     list.txt = item.txt;
            //     list.setting = item.setting;
            //     list.videocreationFile = item.video;
            //     list.mouthAudio.mouthAudioList = item.audio;
            //     group.push(list);
            // });
            // let bgm = {
            //     backGroundList: this.$store.getters.GET_BGM,
            //     ...this.$store.getters.GET_BGM_SET,
            // };
            // data.script.scriptClipsParam.backGroundAudio = bgm;
            // data.script.scriptClipsParam.group = group;
            const data = await this.generate_data();
            // let loading = this.$loading({
            //     lock: true,
            //     text: "系统正在计算裂变数量",
            //     spinner: "el-icon-loading",
            //     background: "rgba(0, 0, 0, 0.7)",
            // });

            if (data.script.scriptClipsParam.canvas == "") {
                data.script.scriptClipsParam.canvas = '{"width"9,"height":16}';
            }
            if (data.script.scriptClipsParam.rate == "") {
                data.script.scriptClipsParam.rate = '{"x":1080,"y":1920}';
            }
            this.load_text_index = 0;
            this.progress = 0;
            this.show_poject_load = true;

            this.loading();
            let res = await getFission(data);
            if (res.code == 200) {
                // this.$message.success("已成功生成裂变组合！");
                this.projectId = res.id;
                this.$store.commit("SET_FISS_ID", res.id);
                this.preview = this.forFiss(res.data.list);
            } else {
                // this.$message.error("裂变失败，code:" + res.code);
            }
        },
        // 查询脚本信息
        async get_sid(sid) {
            let getScript = await getScriptInfo(sid);
            if (getScript.code == 200) {
                let scriptTemp = await this.scriptJson(getScript.data);
                scriptTemp.gruop = scriptTemp.lensGroup;
                // this.scriptInfo = script_info;
                this.$store.commit("RESET_SCRIPT", scriptTemp);
            } else {
                this.$message.error("获取脚本失败，请刷新浏览器或者导入脚本！");
            }
        },
        //脚本信息转格式
        scriptJson(script) {
            script.canvas = JSON.parse(script.canvas);
            script.rate = JSON.parse(script.rate);
            script.lensGroup = JSON.parse(script.lensGroup);
            return script;
        },
        // 旧
        // 选择预览分组
        selected(item) {
            let selected = this.preview[item].selected;
            this.preview[item].selected = selected ? false : true;
            // console.log(this.preview);
        },
        async getPreviewList() {
            // console.log(this.$store.state.fiss_id);
            if (!this.$store.state.fiss_id) return;
            let req = {
                projectId: this.$store.state.fiss_id,
                pageSize: 20,
                currentPage: 1,
                // status: 0,
            };
            let previdew = await getPreviewList(req);
            this.preview = this.forFiss(previdew.data.list);
            // console.log(previdew);
        },
        // 生成裂变
        async getPreview() {
            if (this.scriptInfo == "") {
                this.$alert("请先导入脚本", "注意", {
                    confirmButtonText: "确定",
                });
            } else {
                //脚本模板ID
                let loading = this.$loading({
                    lock: true,
                    text: "系统正在计算裂变数量",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });

                const gruop = [];
                let lensGroup = this.$refs.material.lensGroup;
                let canvas = this.$refs.material.script.canvas;
                let rate = this.$refs.material.script.rate;
                //   // 分组信息遍历
                lensGroup.map((item, index) => {
                    const scriptItem = {
                        title: item.title,
                        sort: index,
                        setting: {
                            mix: "singleLensScissors",
                            transition: "dissolve",
                            duration: 0.5,
                            location: "位置",
                            model: "模式",
                            audio: "音频",
                            volume: "音量",
                            speed: "变速",
                        },
                        cameralens: {
                            speechRate: "语速",
                            tone: "音调",
                            volume: 50,
                            sort: "1",
                            videocreationFile: [],
                        },
                        mouthAudio: {
                            speechRate: "语速",
                            tone: "音调",
                            volume: "音量",
                            mouthAudioList: [
                                {
                                    id: "416988642054104325",
                                },
                                {
                                    id: "416988644809762053",
                                },
                            ],
                        },
                        script: {},
                        audio: [],
                    };
                    scriptItem.setting = item.setting;
                    // 组内视频遍历
                    //   一组视频数据
                    let videoData = [];
                    // 单条视频数据
                    let videoJson = {};
                    item.video.map((vitem, vindex) => {
                        videoJson.id = vitem.id;
                        videoJson.sort = index;
                        videoData.push(videoJson);
                        videoJson = {};
                    });

                    let audioData = [];
                    let audioJson = {};
                    item.audio.map((aitem, aindex) => {
                        audioJson.id = aitem.id;
                        // audioJson.sort = index;
                        audioData.push(audioJson);
                        audioJson = {};
                    });
                    scriptItem.mouthAudio.mouthAudioList = audioData;
                    scriptItem.cameralens.videocreationFile = videoData;
                    gruop.push(scriptItem);
                    console.log(gruop);
                });
                this.senData.script.scriptClipsParam.group = gruop;

                this.senData.frontScriptInfo = JSON.stringify(this.scriptInfo);
                this.senData.projectId = this.projectId;

                this.senData.script.scriptTemplateId = this.scriptInfo.id;
                this.senData.script.scriptClipsParam.canvas =
                    JSON.stringify(canvas);
                this.senData.script.scriptClipsParam.rate =
                    JSON.stringify(rate);
                // console.log(this.senData);
                // console.log(lensGroup);
                let res = await getFission(this.senData);

                loading.close();
                if (res.code == 200) {
                    this.$message.success("已成功生成裂变组合！");
                    this.projectId = res.id;

                    this.preview = this.forFiss(res.data.list);
                } else {
                    this.$message.error("裂变失败，code:" + res.code);
                }
            }
        },
        // 获取脚本信息
        getScriptInfo(id) {
            getScriptInfo(id).then((res) => {
                return res;
                // console.log(res);
            });
        },
        // 裂变分组
        forFiss(list) {
            // console.log(list);
            let recList = [];

            list.forEach((item) => {
                let json = {
                    previewCatalogueSort: "",
                    superRecommendation: "",
                    videos: [],
                };
                json.previewCatalogueSort = item.previewCatalogueSort;
                json.superRecommendation = item.superRecommendation;
                json.selected = false;
                json.videos = item.child;
                json.id = item.id;
                json.timeLine = item.timeLine;
                recList.push(json);
            });
            this.preview = recList;
            return recList;
        },
        back() {
            this.dialogVisible = false;
        },
        // 选择预览分组
        openMenu() {
            let pid = this.$store.state.fiss_id;
            if (!pid) return;
            this.$router.push({
                path: `/make/fiss_list/${pid}`,
            });
        },
        setPlay(list) {
            // this.timeLine = list.timeLine;
            let timeLine = list.timeLine;
            this.active = 1;
            // this.$refs.play.ali_preview(timeLine);
            console.log(this.$refs.play1.ali_preview(timeLine));
        },
        async getTimeLine(index) {
            this.active = 1;
            this.isLoading = true;
            this.preview_data.projectId = "";
            this.preview_data.previewIdList;
            let previewIdList = [];
            this.preview_data.projectId = this.$store.state.fiss_id;
            previewIdList.push(index);
            this.preview_data.previewIdList = previewIdList.toString();
            // let item = {
            //     previewCatalogueSort: index,
            // };
            // json.group.push(item);
            let loading = this.$loading({
                lock: true,
                text: "正在生成预览视频",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let res = await getTimeLine(this.preview_data);
            loading.close();
            if (res.code == 200) {
                this.$message.success("生成预览视频成功");
                this.timeLine = JSON.stringify(res.data);
                // console.log(this.timeLine);
            } else {
                this.$message.error("生成预览视频失败");
                // this.timeLine = JSON.stringify(res);
            }

            this.isLoading = false;
        },
    },
};
</script>

<style lang="less" scoped>
</style>