<style lang="less">
ul {
    margin: 0 !important;
}
.video_box {
    min-width: 0 !important;
    width: 150px !important;
    height: 100px !important;
    box-sizing: border-box;
}
.el-collapse-item__arrow {
    // flex: 1 0 auto;
    order: -1;
    margin: 0;
}
.el-collapse-item.active .mater-group {
    border: 1px solid #e03e3e;
    border-radius: 4px;
    overflow: hidden;
}
.el-empty {
    padding: 0;
}
.el-empty__description p {
    font-size: 12px;
}
.dropdown {
    line-height: 20px;
}
</style>
<style lang="less" scoped>
.dropdown {
    margin: 0 8px 0 auto;
    i {
        background-color: #f1f1f1;
        padding: 1px 4px;
        border-radius: 4px;
    }
}
.mater-group {
    display: flex;
    flex-wrap: wrap;
    padding: 4px;
    .mater-item {
        flex: 0 0 25%;
        width: 0;
        // height: 90px;
        padding: 4px;
        box-sizing: border-box;
        position: relative;
        .img_box {
            background: #f3f3f3;
        }
        i {
            position: absolute;
            top: 8px;
            right: 8px;
            font-size: 12px;
            cursor: pointer;
            z-index: 999;
            background-color: rgba(255, 255, 255, 0.6);
            color: #000;
            border-radius: 50%;
            padding: 2px;
        }
        img {
            width: 100%;
        }
        .file_name {
            font-size: 12px;
            line-height: 1;
            margin-top: 4px;
        }
    }
}
.mater {
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    .mater-top {
        flex: 1;
        display: flex;
        flex-direction: column;
        height: 0;
        overflow: hidden;

        .script-head {
            padding: 10px;

            .title {
                margin-bottom: 10px;
                // flex: 0 0 200px;
            }
            .top-set {
                display: flex;
                justify-content: space-between;
                &-box {
                    display: flex;
                    ul {
                        display: flex;
                        padding: 3px;
                        background-color: #f5f6f8;
                        li {
                            padding: 2px 10px;
                            background-color: #fff;
                        }
                        li + li {
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
        .video-box {
            flex: 1;
            height: 0;
            overflow: hidden auto;
            padding: 10px;
            /deep/ .el-collapse {
                border: none;
            }
            /deep/ .el-collapse-item__header {
                border: none;
            }
        }
    }
    .mater-bottom {
        flex: 0 0 160px;
        height: 0;
    }
}
</style>
<style lang="less" scoped>
.title-box {
    display: flex;
    .icon {
        display: inline-block;
        width: 14px;
        height: 12px;
        background-image: url("../../../assets/images/fission/icon.png");
        background-size: 42px;
        transform: translateY(2px);
        margin-left: 4px;
    }
}
.icon_gruop {
    display: flex;
    align-items: center;
    .icon- {
        &dc {
            background-position-x: 28px;
        }
        &zn {
            background-position-x: 14px;
        }
        &audio {
            background-position-y: 12px;
            background-position-x: 28px;
        }
        &zhuan {
            background-position-y: 12px;
        }
    }
}
.down_menu {
}
</style>
<template>
    <div class="mater">
        <!-- {{ scriptInfo }} -->
        <div class="mater-top">
            <div class="script-head">
                <dl class="top-bar">
                    <!-- <dt class="title">时间设定</dt> -->
                    <dd class="top-set">
                        <div class="top-set-box">
                            <!-- <ul>
                                <li>智能时长</li>
                                <li>限制时长</li>
                            </ul>
                            <div>设置</div> -->
                        </div>
                        <div>
                            <el-button
                                type="primary"
                                size="mini"
                                @click.native="add_group"
                                >添加分组</el-button
                            >
                            <el-button
                                type="primary"
                                size="mini"
                                @click="importShow = true"
                                >导入脚本</el-button
                            >
                        </div>
                    </dd>
                </dl>
            </div>
            <div class="video-box">
                <div class="script-box" v-if="GET_GROUP.length > 0">
                    <el-collapse
                        v-model="group_index"
                        :accordion="false"
                        @change="handleChange"
                    >
                        <el-collapse-item
                            :name="index"
                            v-for="(item, index) in GET_GROUP"
                            :key="index"
                            :class="
                                $store.state.select_fiss.group == index
                                    ? 'active'
                                    : ''
                            "
                        >
                            <template slot="title">
                                <div class="title-box">
                                    <div>
                                        {{ item.title }}
                                    </div>
                                    <ul class="icon_gruop">
                                        <li class="icon_item">
                                            <span
                                                class="icon icon-dan"
                                                v-if="item.setting.mix == 0"
                                            ></span>
                                            <span
                                                class="icon icon-dc"
                                                v-if="item.setting.mix == 1"
                                            ></span>
                                            <span
                                                class="icon icon-zn"
                                                v-if="item.setting.mix == 2"
                                            ></span>
                                        </li>
                                        <li>
                                            <span
                                                v-if="
                                                    item.setting.transition !=
                                                    ''
                                                "
                                                class="icon icon-audio"
                                            ></span>
                                        </li>
                                        <li>
                                            <span
                                                v-if="item.audio.length"
                                                class="icon icon-zhuan"
                                            ></span>
                                        </li>
                                    </ul>
                                </div>
                                <el-dropdown
                                    class="dropdown"
                                    placement="bottom-start"
                                    :tabindex="index"
                                >
                                    <i class="el-icon-more" @click.stop></i>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item
                                            @click.native="
                                                add_impo(item, index, 1)
                                            "
                                            >添加镜头</el-dropdown-item
                                        >
                                        <el-dropdown-item
                                            @click.native="del_group(index)"
                                            >删除分组</el-dropdown-item
                                        >
                                        <el-dropdown-item
                                            @click.native="rename(item, index)"
                                            >重命名</el-dropdown-item
                                        >
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </template>
                            <div class="video-group">
                                <div
                                    class="mater-group"
                                    v-if="item.video.length > 0"
                                >
                                    <div
                                        class="mater-item"
                                        v-for="(video, inx) in item.video"
                                        :key="inx"
                                    >
                                        <div class="img_box">
                                            <i
                                                class="el-icon-close"
                                                @click="delMater(index, inx)"
                                            ></i>
                                            <el-image
                                                style="
                                                    width: 100%;
                                                    height: 90px;
                                                "
                                                :src="video.coverUrl"
                                                fit="contain"
                                            ></el-image>
                                        </div>
                                        <div class="ellipsis file_name">
                                            {{ video.fileName }}
                                        </div>
                                    </div>
                                </div>
                                <div style="width: 100%" description="" v-else>
                                    <el-empty
                                        description="您还未添加素材呢 ^_^"
                                        :image-size="80"
                                    >
                                    </el-empty>
                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
                <div
                    style="
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    "
                    description=""
                    v-else
                >
                    <el-empty
                        description="请添加分组，或者导入脚本 ^_^"
                        :image-size="80"
                        @click.native="add_group"
                    >
                    </el-empty>
                </div>
            </div>
        </div>
        <div class="mater-bottom">
            <AUDIO_TRACK />
        </div>

        <!-- 弹出 -->
        <el-dialog title="提示" :visible.sync="importShow" width="1000px">
            <div style="height: 500px">
                <myScript ref="myscript" :mode="'impo'" />
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="importShow = false">取 消</el-button>
                <el-button type="primary" @click="impoScript()"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>

<script>
// 脚本
import myScript from "@/components/script/scriptGruop.vue";
import AUDIO_TRACK from "@/components/edit/material/audio_track";
import Bus from "@/utils/eventBus";
import { getScriptInfo } from "@/api/script";
import { getProj, getTimeLine } from "@/api/engi.js";
import { mapGetters } from "vuex";
export default {
    name: "SCRIPT",
    props: ["sid", "pid"],
    components: { myScript, AUDIO_TRACK },
    data() {
        return {
            drawer: false,
            importShow: false,
            show_material: false,
            group_index: [],
            group_selected: this.$store.state.select_group,
            // 脚本id
            script: "",
            script_id: this.sid,
            projectid: this.pid,
            select_source: [], //已选素材
            lensGroup: this.$store.state.fiss_group,
            audio: [],
            sourec_type: "",
        };
    },

    mounted() {},
    computed: {
        ...mapGetters(["GET_GROUP"]),
    },
    created() {
        // console.log(this.$store.state);
        // this.getScript();
        if (this.script_id) {
            // console.log("有脚本Id");
            this.getScript();
        }
        if (this.projectid) {
            getProj(this.projectid).then((res) => {
                console.log(res);
            });
        }
        let select_group = this.$store.state.select_fiss.group;
        if (select_group !== "") {
            console.log(select_group);
            this.group_index.unshift(select_group);
        }
    },
    methods: {
        // 导入脚本
        async impoScript() {
            this.group_index = [];
            let scriptTemp = this.$refs.myscript.selected;
            // console.log(scriptTemp);
            this.$store.commit("RESET_SCRIPT", scriptTemp);
            this.importShow = false;
        },
        // 获取脚本
        async getScript() {
            // 查询脚本
            let getScript = await getScriptInfo(this.script_id);

            if (getScript.code == 200) {
                this.script = getScript.data;
                this.setScript();
                // 设置渲染数据
            } else {
                this.$message.error("获取脚本失败，请刷新浏览器或者导入脚本！");
            }
        },
        // 设置脚本
        setScript() {
            const script_data = this.script;
            // console.log(script_data);
            script_data.canvas = JSON.parse(script_data.canvas);
            script_data.rate = JSON.parse(script_data.rate);
            this.lensGroup = JSON.parse(script_data.lensGroup);
            // this.canvas
            // 给脚本每组设置默认值
            this.lensGroup.forEach((item) => {
                let setting = {
                    mix: 0,
                    transition: "",
                    duration: 0.5,
                };
                item.setting = setting;
            });
            this.script = script_data;
            // console.log(this.lensGroup);
        },
        add_group() {
            this.$store.commit("ADD_GROUP");
        },
        // 折叠面板操作钩子
        handleChange(val) {
            let group_index = val[val.length - 1];
            if (group_index !== undefined) {
                let json = {
                    type: "video",
                    group: group_index,
                };
                this.$store.commit("SET_FISS_SELECT", json);
            } else {
                let json = {
                    type: "",
                    group: "",
                };
                this.$store.commit("SET_FISS_SELECT", json);
            }
        },
        // 打开导入素材
        add_impo(item, index, sourec_type) {
            let inop_data = {
                type: "video",
                group: index,
            };
            this.$store.commit("SET_FISS_SELECT", inop_data);
            this.$emit("daoru", inop_data);
        },
        // 设置分组名
        rename(item, index) {
            // console.log(index);
            this.$prompt("请输入分组名", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
            }).then(({ value }) => {
                // item.title = value;
                let group = {
                    title: value,
                    group: index,
                };
                this.$store.commit("SET_GROUP_NAME", group);
                // console.log(item);
                this.$message({
                    type: "success",
                    message: "修改成功",
                });
            });
        },
        // 删除分组
        del_group(index) {
            // this.lensGroup.splice(index, 1);
            console.log(index);
            this.$store.commit("DEL_GROUP", index);
        },
        // 导入素材
        daoru() {
            let fileType = this.sourec_type; //素材类型
            let groupIndex = this.script_index; //所在分组序号
            let ref_data = this.$refs.daoru.select_data;
            let upFiss = {
                type: "video",
                data: ref_data,
            };
            if (fileType == 1) {
                this.$store.commit("SET_FISS", upFiss);
                // ref_data.forEach((item) => {
                //     this.lensGroup[this.script_index].video.push(item);
                // });
            } else if (fileType == 2) {
                // 口播导入
                ref_data.forEach((item) => {
                    this.lensGroup[this.script_index].video.push(item);
                    this.drawer = false;
                });
            } else if (fileType == 3) {
                console.log("BGM");
                // BGM导入 audio
            }
            this.drawer = false;
        },
        // 删除素材
        delMater(group, index) {
            // mater.splice(index, 1);
            // console.log(mater);
            let video = {
                group,
                index,
            };

            this.$store.commit("DEL_VIDEO", video);
        },
    },
};
</script>
