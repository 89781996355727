<style lang="less" scoped>
// @import url("https://g.alicdn.com/thor-server/video-editing-websdk/4.9.0/player.css");
.play-content {
    height: 100%;
    background-color: #000;
}
.play-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    .hd {
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        padding-top: 15px;
        margin-bottom: 15px;
    }
    .play-box {
        flex: 1;
        height: 0;
        .video {
            height: 100%;
        }
    }
    .play-control {
        flex: 0 0 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
    }
}
</style>
<template>
    <div class="play-content">
        <div class="play-box">
            <div
                class="video"
                v-loading="isLoading"
                element-loading-text="正在生成预览视频"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(255, 255, 255, 0.8)"
            >
                <div
                    id="player-wrapper"
                    style="height: 100%; width: 100%"
                ></div>
            </div>
        </div>
    </div>
</template>
<script>
import loadJs from "@/utils/load";
import { getPreview } from "@/api/engi.js";
import { Promise } from "q";
// import { AliyunTimelinePlayer } from "@/assets/ali_player";
export default {
    name: "PlayBox",

    data() {
        return {
            value: "",
            player: "",
            isLoading: false,
        };
    },
    props: ["timeline"],
    watch: {
        async timeline(nVal, oVal) {
            console.log(nVal);
            this.isLoading = true;
            let timeline = JSON.parse(nVal);
            await this.ali_preview(timeline);
            setTimeout(() => {
                this.isLoading = false;
            }, 1000);
        },
    },
    created() {
        loadJs(
            "https://g.alicdn.com/thor-server/video-editing-websdk/4.10.5/player.js"
        ).then((res) => {});
    },
    mounted() {},
    methods: {
        ali_preview(timeline) {
            // console.log(timeline);
            timeline = JSON.parse(timeline);
            if (this.player) {
                this.player.destroy();
            }
            this.player = new window.AliyunTimelinePlayer({
                container: document.querySelector("#player-wrapper"),
                timeline,
                aspectRatio: timeline.AspectRatio,
                minWidth: 1,
                getMediaInfo: async (mediaId, mediaType) => {
                    let res = await getPreview({
                        MediaId: mediaId,
                        Action: "GetMediaInfo",
                    });
                    return res.MediaInfo.FileInfoList[0].FileBasicInfo.FileUrl;
                },
            });
        },
    },
    beforeDestroy() {
        this.player.destroy();
        this.player = "";
    },
};
</script>

<!-- <style src="../../../assets/ali_player.css" scoped>
</style> -->
<style scoped>
@import url("https://g.alicdn.com/thor-server/video-editing-websdk/4.10.5/player.css");
</style>